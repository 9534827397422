import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../components/Breadscrum";
import { Router } from "@reach/router";
import NabidkaRakvi from "../components/NabidkaRakvi";



import GooglePic from "../assets/img/googleFullRating.svg";
import FirmyPic from "../assets/img/firmyFullRating.svg";
import JumbotronV2 from "../components/brandingV2/JumbotronV2";
import LinkButtonV2 from "../components/brandingV2/LinkButtonV2";
import FAQ from "../components/FrequentlyAskedQ";


const HospiceInner = styled.section`

  .noResults {
    text-align: center;
    font-size: 18px;
    font-family: Visuelt-Regular;
    color: rgba(0,0,0,0.7);
  }

  .leftJumbo {
    max-width: 750px !important;

    .buttons {
      display: block !important;

      .active {
        background: #3A557C !important;
        color: #fff !important;

        &:hover {
          background: #2A4164 !important;
        }
      }

      .linkButton {
        display: inline-block !important;
        margin-bottom: 15px !important;
        margin-right: 15px !important;

        @media (max-width: 500px) {
          margin-bottom: 10px !important;
          margin-right: 10px !important;
        }

        @media (max-width: 380px) {
          margin-bottom: 7px !important;
          margin-right: 7px !important;
        }

        a {
          font-size: 16px;
          padding: 12px 18px;

          @media (max-width: 500px) {
            font-size: 15px;
            padding: 8px 14px;
          }

          @media (max-width: 380px) {
            font-size: 14px;
            padding: 6px 8px;
            width: fit-content !important;
          }
        }
      }
    }
  }

  .list {
    background: #f4f3f0;
    padding: 60px 0;

    @media (max-width: 899px) {
      padding: 40px 0;
    }

    .listInner {
      max-width: 1150px;
      margin: 0 auto;
      width: calc(100% - 100px);

      @media (max-width: 900px) {
        max-width: 700px;
        width: calc(100% - 50px);
      }

      .location {
        margin-bottom: 60px;

        @media (max-width: 899px) {
          margin-bottom: 40px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        h2 {
          font-family: NewKansas;
          font-size: 28px;
          color: #4D453D;
          margin-bottom: 30px;
        }

        .locationInner {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1;
          grid-column-gap: 30px;
          grid-row-gap: 30px;

          @media (max-width: 1000px) {
            grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width: 630px) {
            grid-template-columns: repeat(1, 1fr);
          }

          .bubble {
            background: #FFFFFF;
            box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
            border-radius: 10px;
            padding: 20px;
            height: fit-content;

            span {
              background: #dbe3da;
              color: #132C1C;
              font-size: 14px;
              font-family: Visuelt-Medium;
              padding: 5px 10px;
              border-radius: 5px;
              display: block;
              width: fit-content;
              margin-bottom: 15px;
            }

            h3 {
              font-size: 20px;
              font-family: Visuelt-Medium;
              border-bottom: 1px solid #E5E5E5;
              padding-bottom: 15px;
              margin-bottom: 15px;
            }

            p {
              font-size: 16px;
              font-family: Visuelt-Regular;
              margin-bottom: 15px;
              color: #394B5C;

              b {
                color: black;
                font-weight: normal;
                font-family: Visuelt-Medium;
              }

              a {
                color: black;
                font-family: Visuelt-Medium;
                text-decoration: underline;
              }
            }

            .button {
              background: #CBD3DE;
              color: #243A58;
              cursor: pointer;
              font-family: Visuelt-Medium;
              font-size: 16px;
              padding: 15px 26px;
              border-radius: 5px;
              text-decoration: none;
              display: block;
              width: fit-content;
              margin-top: 20px;

              &:hover {
                background: #B5C4D9;
              }
            }
          }
        }
      }
    }
  }
`;




const SeznamHospicu = ({data}) => {
  let location = typeof window !== 'undefined' ? window.location.href : '';

  const faqData = [
    {
      otazka: "Platí se hospic?",
      odpoved: `Ano, hospic se platí, ale ne vždy v plné výši. Náklady mohou být pokryty zdravotním pojištěním nebo jinými zdroji financování. Hospice jsou financovány z veřejných zdrojů a dary.`
    },
    {
      otazka: "Jaké jsou podmínky přijetí do hospice?",
      odpoved: `Podmínky pro přijetí do hospice se mohou lišit v závislosti na konkrétním hospici. Obecně platí, že pacient musí mít nevyléčitelnou nemoc a jeho stav musí být takový, že nepotřebuje akutní léčbu v nemocnici. Naopak potřebuje paliativní péči a zlepšit kvalitu zbytku života.`
    },
    {
      otazka: "Jak funguje hospic?",
      odpoved: `Hospic poskytuje komplexní péči zaměřenou na zlepšení kvality života pacientů s nevyléčitelnými onemocněními. Zahrnuje péči o fyzické, psychické, sociální a duchovní potřeby pacientů a jejich rodin.`
    },
    {
      otazka: "Čím se domácí hospic odlišuje od lůžkového?",
      odpoved: `Domácí hospic poskytuje péči v domácím prostředí pacienta a lůžkový hospic poskytuje péči v nemocničním nebo specializovaném zařízení. Domácí hospic klade důraz na podporu rodiny a zachování pacientovy pohodlné a bezpečné domácí prostředí.`
    },
    {
      otazka: "Jaký je nejlepší hospic?",
      odpoved: `Neexistuje jeden nejlepší hospic, protože každý pacient má jedinečné potřeby a preference. Je důležité vyhledat hospic, který bude nejlépe vyhovovat potřebám konkrétního pacienta a jeho rodiny.`
    },
  ];

  let places = [
    "Praha",
    "Středočeský kraj",
    "Jihočeský kraj",
    "Plzeňský kraj",
    "Karlovarský kraj",
    "Ústecký kraj",
    "Liberecký kraj",
    "Královéhradecký kraj",
    "Pardubický kraj",
    "Vysočina",
    "Jihomoravský kraj",
    "Olomoucký kraj",
    "Moravskoslezský kraj",
    "Zlínský kraj",
  ];

  let categories = [
    {
      name: "Lůžkové hospice",
      slug: "luzkove-hospice",
      h1: "Seznam lůžkových hospiců v ČR",
      p: "Lůžkový hospic je specializované zařízení, kde jsou ubytováni pacienti s nevyléčitelnými onemocněními v pokročilé fázi. Pacientům i jejich rodinám je poskytována komplexní paliativní, sociální, psychická a duchovní péče.",
      title: "Seznam lůžkových hospiců v ČR (2024) | Goodbye.cz",
      desc: "Přehledný seznam veškerých lůžkových hospiců v České republice. Pomůžeme vám najít to správné místo pro vašeho blízkého.",
    },
    {
      name: "Mobilní (domácí) hospice",
      slug: "mobilni-domaci-hospice",
      h1: "Seznam mobilních (domácích) hospiců v ČR",
      p: "Mobilní (domácí) hospice jsou týmy specialistů poskytující paliativní péči pacientům v jejich domácím prostředí. Zaměřují se na zlepšení kvality zbytku života a podporu rodiny. Tyto služby zahrnují nepřetržitou dostupnost lékaře a zdravotních sester a také psychickou, duchoví a další potřebnou podporu pro pacienty a jejich nejbližší.",
      title: "Seznam mobilních (domácích) hospiců v ČR (2024) | Goodbye",
      desc: "Kompletní seznam mobilních, také nazývaných domácích, hospiců v ČR. Najděte ten, který bude pro vás a vaše blízké tím ideálním.",
    },
    {
      name: "Dětské hospice",
      slug: "detske-hospice",
      h1: "Seznam dětských hospiců v ČR",
      p: "Dětské hospice jsou specializovaná zařízení, která poskytují péči a podporu dětem s nevyléčitelnými onemocněními a jejich rodinám. Dětem i jejich rodinám je dostupná jak zdravotní, tak i psychická, duchovní a sociální podpora.",
      title: "Seznam dětských hospiců v ČR (2024) | Goodbye.cz",
      desc: "Seznam veškerých dětských hospiců v ČR. Pomůžeme vám vybrat to nejlepší místo právě pro vaše dítě a pro vás.",
    },
    {
      name: "Rodinné pokoje",
      slug: "rodinne-pokoje",
      h1: "Seznam rodinných pokojů v ČR",
      p: "Rodinné pokoje jsou speciální místnosti v lůžkových hospicích, které umožňují rodinám zůstat s jejich blízkým během jejich pobytu a poskytují jim pohodlí a soukromí.",
      title: "Seznam rodinných pokojů v ČR (2024) | Goodbye.cz",
      desc: "Seznam veškerých hospiců, které poskytují nabídku rodinných pokojů, abyste mohli s vaším blízkým strávit co nejvíce času.",
    },
    {
      name: "Týmy paliativní péče",
      slug: "tymy-paliativni-peci",
      h1: "Seznam týmů paliativní péče v ČR",
      p: "Týmy paliativní péče jsou specializovaní zdravotní pracovníci, kteří poskytují komplexní péči pacientům s nevyléčitelnými onemocněními a jejich rodinám. Jsou to jak zdravotní sestry a lékaři, tak sociální pracovníci, kteří vám poskytnou potřebnou duchovní, psychickou a sociální pomoc a podporu.",
      title: "Seznam týmů paliativní péče v ČR (2024) | Goodbye.cz",
      desc: "Seznam týmů paliativní péče v České republice. Pomůžeme vám najít ideální řešení právě pro vás a vaše nejbližší.",
    },
    {
      name: "Paliativní ambulance",
      slug: "paliativni-ambulance",
      h1: "Seznam paliativních ambulancí v ČR",
      p: "Paliativní ambulance jsou zdravotnická zařízení, která nabízejí mobilní paliativní péči pro pacienty v jejich domácnosti, v nemocnicích a v jiných zařízeních.",
      title: "Seznam paliativních ambulancí v ČR (2024) | Goodbye.cz",
      desc: "Kompletní seznam všech paliativních ambulancí poskytujících služby v České republice. Najděte ideální řešení pro vašeho blízkého.",
    },
    {
      name: "Agentury domácí péče",
      slug: "agentury-domaci-peci",
      h1: "Seznam agentur domácí péče v ČR",
      p: "Agentury domácí péče poskytují především zdravotnické služby pro seniory a pacienty s omezenou pohyblivostí v domácím prostředí, jako je například pravidelné podávání léků, převazy ran, odběry krve, infuze a podobně. Služba je hrazena ze zdravotního pojištění.",
      title: "Seznam agentur domácí péče v ČR (2024) | Goodbye.cz",
      desc: "Seznam agentur domácí péče v ČR, které poskytují především zdravotnické služby. Najděte to správné řešení pro vás.",
    },
    {
      name: "Pečovatelské služby",
      slug: "pecovatelske-sluzby",
      h1: "Seznam pečovatelských služeb v ČR",
      p: "Pečovatelské služby jsou specializované organizace, které nabízejí pomoc při péči o pacienty s omezenou pohyblivostí a seniory v domácím prostředí, jako je například pomoc s hygienou, donáškou jídla, nákupů nebo pomoc s a úklidem.",
      title: "Seznam pečovatelských služeb v ČR (2024) | Goodbye.cz",
      desc: "Seznam pečovatelských služeb v České republice, které poskytují služby, jako např. pomoc s hygienou, úklidem, stravováním apod.",
    },
    {
      name: "Půjčovny pomůcek",
      slug: "pujcovny-pomucku",
      h1: "Seznam půjčoven pomůcek v ČR",
      p: "Půjčovny pomůcek nabízejí různé zdravotnické a kompenzační pomůcky pro seniory a pacienty, jako jsou například invalidní vozíky, polohovatelné zdravotní postele a chodítka.",
      title: "Seznam půjčoven paliativních pomůcek (2024) | Goodbye.cz",
      desc: "Seznam půjčoven pomůcek v České republice. Najděte tu nejbližší půjčovnu pomůcek, která vám bude vyhovovat.",
    },
    {
      name: "Domovy pro seniory",
      slug: "domovy-pro-seniory",
      h1: "Seznam domovů pro seniory v ČR",
      p: "Domovy pro seniory jsou zařízení, kde se starší lidé mohou ubytovat a získat podporu při péči o své zdraví a denní potřeby. V domovech pro seniory můžete vašeho blízkého navštěvovat a trávit společně příjemné chvíle.",
      title: "Seznam domovů pro seniory v ČR (2024) | Goodbye.cz",
      desc: "Seznam domovů pro seniory v České republice. Pomůžeme vám najít to správné místo pro vašeho blízkého.",
    },
    {
      name: "Odlehčovací služby",
      slug: "odlehcovaci-sluzby",
      h1: "Seznam odlehčovacích služeb v ČR",
      p: "Odlehčovací služby jsou organizace, které nabízejí pomoc a podporu pečovatelům, aby mohli mít čas na odpočinek a zajištění svých vlastních potřeb. Odlehčovací služby většinou poskytují několika denní pobyty, během kterých poskytují pacientům pečovatelské a v případě potřeby i zdravotní pomoc.",
      title: "Seznam odlehčovacích služeb v ČR (2024) | Goodbye.cz",
      desc: "Kompletní seznam poskytovatelů odlehčujících služeb v ČR. Najděte ideální řešení pro vás a vašeho blízkého.",
    },
  ];

  // get last string from location between slashes and filter categories by it
  let currentCategoryName = location.split('/').pop();

  if (currentCategoryName === "") {
    // if last string is empty, remove last character from location and get last string again
    location = location.slice(0, -1);
    currentCategoryName = location.split('/').pop();
  }

  if (location === "") {
    // if location is empty, set currentCategoryName to "seznam-hospicu"
    currentCategoryName = "seznam-hospicu";
  }

  let currentCategory = categories.filter((item) => {
    console.log(item.slug, currentCategoryName);
    return (
      item.slug === currentCategoryName
    )
  });



  if (currentCategoryName === "seznam-hospicu") {
    currentCategory = [{
      name: "",
      slug: "",
      title: "Seznam všech hospiců v ČR (Aktualizovaný 2024) | Goodbye.cz",
      desc: "Přehledný seznam všech hospiců, léčeben dlouhodobě nemocných (LDN), agentur domácí péče a ostatních paliativních služeb v České republice. Snažíme se vám pomoci vybrat ten nejlepší hospic pro vašeho blízkého.",
      h1: "Seznam hospiců v ČR",
      p: "Přehledný seznam všech hospiců, léčeben dlouhodobě nemocných (LDN), agentur domácí péče a ostatních paliativních služeb v České republice. Snažíme se vám pomoci vybrat ten nejlepší hospic pro vašeho blízkého.",
    }];
  }


  currentCategory = currentCategory[0];




  let hospice = data.allContentfulOrganizace.edges;
  let hospiceFiltered;

  if (currentCategoryName === "seznam-hospicu") {
    hospiceFiltered = hospice;
  }
  
  else {
    hospiceFiltered = hospice.filter((item) => {
      return (
        item.node.category === currentCategory.name
      )
    });
  }
  
  let breadscrumList = [
    {name: "Domů", url: "/"},
    {name: "Seznam paliativních služeb", url: `/seznam-hospicu/`},
  ];

  if (currentCategoryName !== "seznam-hospicu") {
    breadscrumList.push({name: currentCategory.name, url: `/seznam-hospicu/${currentCategory.slug}/`});
  }




  return (
    <Layout V2>
      
      <SEO
        title={currentCategory.title}
        customTitle
        description={currentCategory.desc}
      />

      <HospiceInner>

        

        <JumbotronV2
          noSplatky
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          h1={currentCategory.h1}
          hideSecondHeading
          p={currentCategory.p}
          noReview
          breadscrum={
            <Breadscrum
              visible={true}
              stranky={breadscrumList}
            />
          }
          hospice
          preCtaText={"Vyhledat podle:"}
          customCta={<>
            <LinkButtonV2 to="/seznam-hospicu/" light text="Všechny" />
            {categories.map((item) => {
              return <LinkButtonV2 key={item.name} to={`/seznam-hospicu/${item.slug}/`} light text={item.name} />
            })}
          </>}
        />

        <section className="list">
          <div className="listInner">
            {hospiceFiltered.length === 0 && <p className="noResults">Bohužel nebyly nalezeny žádné výsledky.</p>}

            {places.map((place, index) => {

              // display only if there is an organization in the place

              var hospiceInLocation = hospiceFiltered.filter(h => h.node.placeCategory === place);

              if (hospiceInLocation.length === 0) {
                return null;
              }

              else {
                return (
                  <div className="location" key={index}>
                    <h2>{place}</h2>
                    <div className="locationInner">
                      {hospiceInLocation.map((hospice, index) => {

                        var h = hospice.node;

                        return (
                          <div key={h.name} className="bubble">
                            <span>{h.category}</span>
                            <h3>{h.name}</h3>
                            <p>Adresa: <b>{h.address}</b></p>
                            <p>Telefon: <a href={`tel:${h.phone}`}>{h.phone}</a></p>
                            <p>E-mail: <a href={`mailto:${h.email}`}>{h.email}</a></p>
                            <p>{h.description}</p>
                            <a href={h.url} rel="nofollow" className="button" target="_blank">Webová stránka</a>
                          </div>
                        )
                      })}
                    </div>
                  </div> 
                )
              }
            })}
          </div>
        </section>

        <FAQ data={faqData}/>


      </HospiceInner>

    </Layout>
  );
}

export const query = graphql`
  query {
    jumboObrazek: file(relativePath: { eq: "hospice.png" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jumboObrazekMobile: file(relativePath: { eq: "hospice.png" }) {
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice1.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice1Small.png" }) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    allContentfulOrganizace {
      edges {
        node {
          id
          description
          contentful_id
          category
          address
          email
          name
          phone
          placeCategory
          url
        }
      }
    }
  }
`;



export default SeznamHospicu;
